<template>
  <div>
    <div class="ll-box">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Configuration') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Service Route') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- Add Btn -->
    <div class="d-flex justify-content-end">
      <b-button
        variant="info"
        :to="{ name: 'create-service-route' }"
        v-permission="[186]"
      >
        <span class="text-nowrap">{{ $t('Create') }}</span>
      </b-button>
    </div>
    </div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-input-group>
              <b-input-group-prepend
                is-text
                v-b-toggle.filterS
                class="ll-outline">
                 <svg class="ll-ficon" aria-hidden="true">
                  <use xlink:href="#icon-filter"></use>
                </svg>
              </b-input-group-prepend>
              <b-form-input
                v-model="statusNo"
                class="searchInput"
                placeholder=""
              />
              <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                @click="searchById"
                >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
                />
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Filters sidebar -->
    <b-sidebar id="filterS" title="" right shadow backdrop bg-variant="white">
      <div class="px-3">
        <app-collapse class="ll-collapse">
          <app-collapse-item :title="$t('Organization')">
            <v-select
              id="customer"
              :options="customerList"
              label="customer"
              @input="getCustomer"
              placeholder=""
            ></v-select>
          </app-collapse-item>
          <app-collapse-item :title="$t('Bounded Services')">
            <b-row
              class="mb-2"
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="4">
                <v-select
                  :options="bSerList1"
                >
                </v-select>
              </b-col>
              <b-col md="4">
                <v-select
                  :options="bSerList2"
                >
                </v-select>
              </b-col>
              <b-col md="4">
                <b-button
                  variant="outline-danger"
                  class="mt-0"
                  @click="removeItem(index)"
                >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>{{ $t('Delete') }}</span>
                </b-button>
              </b-col>
            </b-row>
            <b-button
              variant="primary"
              @click="repeateAgain"
            >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
              <span>{{ $t('Add New') }}</span>
            </b-button>
          </app-collapse-item>
          <app-collapse-item :title="$t('Is Active')">
            <v-select
              id="isActive"
              :options="statusList"
              label="status"
              placeholder=""
              @input="selStatus"
            ></v-select>
          </app-collapse-item>
        </app-collapse>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear"
          >
            {{ $t('Clear') }}
          </b-button>
          <b-button variant="info"
            v-b-toggle.filterS
            @click="filterSearch">
            {{ $t('Search') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>

    <!-- table -->
    <b-table
      ref="refUserListTable"
      class="position-relative ll-table"
      :items="rows"
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      v-permission="[185]"
      :sticky-header="true"
    >
      <template #cell(isEnabled)="row">
        <b-badge
          :variant="row.value ? 'info' : 'secondary'"
          class="ll-badge">
          <span>{{ row.value ? 'Active' : 'Inactive' }}</span>
        </b-badge>
      </template>
      <!-- Column: Actions -->
      <template #cell(action)="data">
        <b-button
          variant="flat-secondary"
          class="btn-icon rounded-circle ll-act"
          v-b-toggle.modifyS
          v-permission="[187]"
        >
          <feather-icon icon="EditIcon" @click="onEdit(data.item.id)"/>
        </b-button>
      </template>
    </b-table>
    <div class="d-flex justify-content-between flex-wrap ll-p">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>

    <!-- Edit sidebar -->
    <b-sidebar id="modifyS" title="Edit Status Code" right shadow backdrop>
      <div class="px-3">
        <b-form-group
          class="g-label"
          label="internalStatusNo"
          label-for="sType"
        >
          <b-form-input
            id="sType"
            v-model="editInfo.internalStatusNo"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="originStatusCode"
          label-for="statusNo"
        >
          <b-form-input
            id="statusNo"
            v-model="editInfo.originStatusCode"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="originStatusMessage"
          label-for="statusDes"
        >
          <b-form-input
            id="statusDes"
            v-model="editInfo.originStatusMessage"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="providerCode"
          label-for="tag"
        >
          <b-form-input
            id="tag"
            v-model="editInfo.providerCode"
          />
        </b-form-group>
        <b-form-group
          class="g-label"
          label="Is Active"
        >
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="editInfo.isEnabled"
              name="some-radios"
              value="true"
            >
              Active
            </b-form-radio>
            <b-form-radio
              v-model="editInfo.isEnabled"
              name="some-radios"
              value="false"
            >
              Inactive
            </b-form-radio>
          </div>
        </b-form-group>
        <div class="ll-btn">
        <b-button
          variant="secondary"
          class="mr-1"
          @click="onClear1"
        >
          {{ $t('Clear') }}
        </b-button>
        <b-button variant="info"
          @click="editSubmit"
          v-b-toggle.modifyS>
          {{ $t('Submit') }}
        </b-button>
      </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  // BTooltip,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BSidebar,
  VBToggle,
  BTable,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem,
  BBadge, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    // BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    BFormRadio,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-toggle': VBToggle,
    permission,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'providerCode', label: this.$t('Service ID'), sortable: true },
        { key: 'customerName', label: this.$t('Organization'), sortable: true },
        { key: 'internalStatusNo', label: this.$t('Order Type'), sortable: true },
        { key: 'originStatusCode', label: this.$t('Bounded Services'), sortable: true },
        { key: 'organizationId', label: this.$t('Update time'), sortable: true },
        // { key: 'roles', label: 'Exter Ref', sortable: true },
        // { key: 'roles', label: 'Description', sortable: true },
        { key: 'isEnabled', label: this.$t('Status'), sortable: true },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      searchTerm: '',
      customerList: [],
      statusTypeList: [],
      statusList: [
        { value: null, status: 'all' },
        { value: true, status: 'active' },
        { value: false, status: 'inactive' },
      ],
      formTime: '',
      toTime: '',
      condition: {
        page: '1',
        size: '50',
        orderMole: 0,
        orderBy: 'id',
        statusNo: [],
        statusType: [],
        isEnabled: null,
        providerCode: '',
      },
      statusNo: '',
      editInfo: {
        id: '',
        internalStatusNo: '',
        isEnabled: null,
        originStatusCode: '',
        originStatusMessage: '',
        providerCode: '',
      },
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 1,
      bSerList1: ['LMD', 'WHF'],
      bSerList2: ['Labelling', 'Routing', 'SMS', 'Billing'],
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    // this.getList()
    // this.queryCustomer()
    // this.queryStatusType()
  },
  methods: {
    // 首次查询
    getList() {
    //   this.$http.post('/csCansExt/search', this.condition).then(res => {
    //     console.log('ss', res.data.data)
    //     if (res.data.code === '10001') {
    //       this.$router.push({ name: 'auth-login' })
    //     } else if (res.data.code === '200') {
    //       const info = res.data.data
    //       this.rows = info.content
    //       this.page = info.page
    //       this.size = info.size
    //       this.totalSize = info.totalSize
    //       this.totalPage = info.totalPage
    //     }
    //   }).catch(error => console.log(error))
    },
    // 点击单行
    onRowClick() {
      // console.log('onRowClick')
    },
    onSearch() {
      // console.log('onSearch')
    },
    onClear() {
      this.condition = {}
    },
    // 条件过滤搜索
    filterSearch() {
      this.getList()
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
    },
    // 根据Id查询
    searchById() {
      if (this.statusNo.trim() !== '') {
        this.condition.statusNo = this.statusNo.trim().split(/[(\r\n)\r\n]+/)
        this.getList()
      } else {
        this.condition.statusNo = []
        this.getList()
      }
    },
    // 获取客户名
    getCustomer(c) {
      this.condition.providerCode = c.providerCode
    },
    // 获取statusType
    getStatusType(t) {
      this.condition.statusType = t.statusType.split()
    },
    selStatus(s) {
      this.condition.isEnabled = s.value
    },
    // 获取客户名列表
    queryCustomer() {
      this.$http.get('csCansExt/getCustomerList').then(res => {
        this.customerList = res.data.data
      })
    },
    // 获取statusType列表
    queryStatusType() {
      this.$http.get('csCansExt/getStatusTypeList').then(res => {
        if (res.data.data[0] !== null) {
          this.statusTypeList = res.data.data
        } else {
          this.statusTypeList = []
        }
      })
    },
    // 点击编辑按钮
    onEdit(code) {
      this.$http.get(`csCansExt/info/${code}`).then(res => {
        this.editInfo = res.data.data
      })
    },
    // 编辑提交
    editSubmit() {
      this.$http.put('csCansExt/update', this.editInfo).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Edit Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.getList()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    onClear1() {
      this.editInfo = {}
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
      this.$nextTick(() => {
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
      })
    },
  },
}
</script>
<style scoped>
/* .ll-p{
  position: fixed;
  bottom: 40px;
  right: 10px;
} */
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ll-act{
  height: 15px;
  width: 15px;
}
.btn .feather{
  position: relative;
  top: -8px;
  left: -5px;
}
</style>
<style>
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir=ltr] .b-sidebar.b-sidebar-right > .b-sidebar-header .close{
  margin-left: unset;
  margin-right: unset;
  font-size: 20px;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
.btn .feather[data-v-30e10d9f] {
  top: 0;
}
</style>
